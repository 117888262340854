import { ADDCATEGORY, REMOVECATEGORY, UPDATECATEGORY, ADDCATEGORYVIDEO, REMOVECATEGORYVIDEO } from "../Types/CategoryTypes";

const initialState = {
    createNewCategoryData: undefined,
    updateCategoryData: undefined,
    deleteCategoryData: undefined,
    createCategoryVideoData: undefined,
    deleteCategoryVideoData: undefined   
}

const CategoryReducers = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case ADDCATEGORY:
            return {
                ...modifiedState,
                createNewCategoryData: action.payload              
            }
        case UPDATECATEGORY:

            return {
                ...modifiedState,
                updateCategoryData: action.payload
            }

        case REMOVECATEGORY:

            return {
                ...modifiedState,
                deleteCategoryData: action.payload
            }
            
        case ADDCATEGORYVIDEO:

            return {
                ...modifiedState,
                createCategoryVideoData: action.payload
            }
        case REMOVECATEGORYVIDEO:

            return {
                ...modifiedState,
                deleteCategoryVideoData: action.payload
            }        
              
        default:{}
    }

    return modifiedState;
}


export default CategoryReducers; 