import { combineReducers } from 'redux';
import loginreducers from './Reducers/LoginReducers';
import DashboradReducers from './Reducers/DashboradReducers';
import SongReducers from './Reducers/SongReducers';
import RecitalReducers from './Reducers/RecitalReducers';
import ActivityReducers from './Reducers/ActivityReducers';
import CategoryReducers from './Reducers/CategoryReducers';
import AssessmentReducers from './Reducers/AssessmentReducers';




export default combineReducers({

    loginreducers, DashboradReducers, SongReducers, RecitalReducers, ActivityReducers, CategoryReducers,AssessmentReducers

})