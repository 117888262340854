import { CLASSROOMS, STUDENTLIST, USERDATAFORGOT, VIDEOPIECE } from "../Types/AssessmentTypes"

const initialState = {
    studentListData: undefined,
    videoPieceData: undefined,
    classRoomsData: undefined,
    userDataForgotData: undefined


}


const AssessmentReducers = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {


        case STUDENTLIST:

            return {
                ...modifiedState,
                studentListData: action.payload.studentListData
            }

        case VIDEOPIECE:

            return {
                ...modifiedState,
                videoPieceData: action.payload.videoPieceData
            }

        case CLASSROOMS:

            return {
                ...modifiedState,
                classRoomsData: action.payload.classRoomsData
            }
        case USERDATAFORGOT:

            return {
                ...modifiedState,
                userDataForgotData: action.payload.userDataForgotData
            }
        default: { }
    }

    return modifiedState;
}


export default AssessmentReducers;