
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './store/index';
// import { apply } from 'redux-saga/effects';
// import logger from 'redux-logger'
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.log(e);
  }
}

function loadStoreFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    console.log(e)
    return undefined
  }
}
const persistedState = loadStoreFromLocalStorage()
const composeEnhancers = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose;
const store = createStore(reducer, persistedState, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk)
));

store.subscribe(() => {  saveToLocalStorage(store.getState()); })//saveToLocalStorage(store.getState()))
ReactDOM.render(<Provider store={store}><App /></Provider>,
  document.getElementById('root')

);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
