import React, { useState, useEffect } from 'react';
import "../css/signup.css"
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { Card ,Button} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-responsive-modal/styles.css';
import "antd/dist/antd.css"; 
import { ThreeDots } from 'react-loader-spinner'; 
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const NewUserForm = (props) => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        roll_no: "",
        grade: "",
        section: "",
        instrument: ""
    })
    const [grades, setGrades] = useState([]);
    const [sections, setSections] = useState([]);
    const [instruments, setInstruments] = useState([]);
    const [selectedInstrument, setSelectedInstrument] = useState('');
    const { academic_year_id, school_id, id } = props.location?.state?.data || {};
    const [loading, setLoading] = useState(false);
    const [grade_id, setGradesId] = useState('');
    const [section_id, setSectionId] = useState('');
    const [instrument_id, setInstrumentsId] = useState('');

    const onSubmitRollNumber = async (student_id) => {
      try {
        const baseurl = process.env.REACT_APP_SERVER_URL;
        const body = {
          "roll_no": formData.roll_no,
          "school_id":school_id,
          "academic_year_id": academic_year_id,
          "grade_id": grade_id,
          "section_id": section_id,
          "instrument_id": instrument_id,
          "student_id": student_id
        }
        const response = await axios.post(`${baseurl}/classroom/map-student`, body);
        console.log('rollNumber post response', response);
      } catch (err) {
        toast.error('We are unable to process your request, please try again.....')
        console.log(err);
      }
    }

    const onSubmitForm = async () => {
      try {
        const baseurl = process.env.REACT_APP_USERS_API_URL;
        const body = {
          "parent_id": id,
          "school_id": school_id,
          "instrument_name": selectedInstrument,
          "instrument_id": instrument_id,
          "name": formData.name,
          "email": formData.email,
          "password": formData.email
        }
        const response = await axios.post(`${baseurl}/user/register`, body);
        if (response.data && response.data.success) { 
          const { message, student_id } = response.data;
          onSubmitRollNumber(student_id);
          setLoading(false);
          toast.success(message)
          setTimeout(() => {
            props.history.goBack();
          }, 2000);
        }
      } catch (error) {
        setLoading(false);
        console.log('Erorrrrrrrrrrrr', error);
      }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        onSubmitForm();
    }

    const getGrades = async () => {
      try {
        const baseurl = process.env.REACT_APP_SERVER_URL;
        const response = await axios.get(`${baseurl}/musical-grades`);
        if (response && response.data) {
          const { results } = response.data
          setGrades(results);
        }
      } catch (error) {
        console.error("Error while getting grades", error);
      }
    }

    const getSchoolData = async () => {
      try {
        const baseurl = process.env.REACT_APP_SERVER_URL;
        const response = await axios.get(`${baseurl}/school/section/instrument?school_id=${school_id}&academic_year_id=${academic_year_id}`)
        if (response.data && response.data.status) {
          const result  = response.data.result;
          const sectionData = result.section
          const instrumentData = result.instrument
          setSections(sectionData);
          setInstruments(instrumentData);
        } else {
          const errorMessage = response.data ? response.data.message : 'Something went wrong';
          alert(errorMessage);
          console.log('Error: ' + errorMessage);
        }
      } catch (error) {
        console.error('Error while getting sections', error);
      }
    }
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSectionChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setSectionId(value);
      }
  
      const handleInstrumentChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setSelectedInstrument(instruments[value]);
        setInstrumentsId(value);
      }
  
      const handleGradeChange = (e) => {
        const selectedGrade = grades.find(
          (grade) => grade.title === e.target.value
        )
        if (selectedGrade) {
          setFormData({ ...formData, grade: selectedGrade.title });
          setGradesId(selectedGrade.id);
        }
      }

    useEffect(() => {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove();  // removing the spinner element
      }
      getGrades();
      getSchoolData();
    }, []);

    return (
        <div className="lb-frame" style={{ justifyContent: 'center', alignItems: 'center'}}>

        <ToastContainer position="to-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        <Grid container className="justify-content-center align-items-center">

          <Grid item xs={11} sm={8} md={4} lg={3} className="shadow-lb-common">

            <Card className='p-3 text-left box-shadow-lb' md={5} style={{overflow: 'hidden'}}>

              <div className="text-center p-3 school-name-heading">

                <svg width="156" height="53" viewBox="0 0 156 53" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path fillRule="evenodd" clipRule="evenodd" d="M30.2562 0.304207C21.1286 0.304207 9.78426 7.38898 13.2614 20.0807C16.5213 15.3431 19.955 15.2127 25.0404 15.2127H48.3376C61.1162 15.2127 66.4189 4.78109 66.4189 0.260742H30.2562V0.304207Z" fill="#D60000" />

                  <path fillRule="evenodd" clipRule="evenodd" d="M4.82919 43.7253C6.65472 43.7253 8.17599 45.2031 8.17599 47.0721C8.17599 48.8976 6.69818 50.4189 4.82919 50.4189C4.00335 50.4189 3.22098 50.1146 2.61247 49.5931C1.61278 48.7238 1.00427 46.8982 0.786944 45.6378C-1.29937 33.2503 8.21945 20.9062 20.9982 20.9062H41.4267C41.4267 25.4266 36.124 35.8582 23.3453 35.8582H14.8261C8.56718 35.8582 3.82949 39.77 3.30791 44.1165C3.78603 43.8557 4.30761 43.7253 4.82919 43.7253Z" fill="#D60000" />

                  <path d="M72.678 17.9946H75.5901V30.7298H83.8484V33.1204H72.678V17.9946Z" fill="#276FB7" />

                  <path d="M96.8878 30.7738V33.1209H85.0654V17.9951H96.5836V20.3422H87.9776V24.2541H95.5839V26.6012H87.9776V30.7738H96.8878Z" fill="#276FB7" />

                  <path d="M110.188 29.6002H102.278L100.713 33.0774H97.6703L104.755 17.9951H107.624L114.839 33.1209H111.753L110.188 29.6002ZM109.188 27.427L106.189 20.8203L103.234 27.427H109.188Z" fill="#276FB7" />

                  <path d="M126.053 33.1209L122.836 28.6875C122.706 28.6875 122.489 28.6875 122.228 28.6875H118.664V33.1209H115.708V17.9951H122.184C123.532 17.9951 124.749 18.2124 125.748 18.6471C126.748 19.0817 127.531 19.6902 128.096 20.5161C128.617 21.3419 128.921 22.2981 128.921 23.3848C128.921 24.5148 128.617 25.5145 128.052 26.3404C127.487 27.1662 126.661 27.7747 125.575 28.1659L129.182 33.1209H126.053ZM124.966 21.168C124.314 20.6465 123.314 20.3857 122.054 20.3857H118.62V26.3838H122.054C123.314 26.3838 124.314 26.123 124.966 25.6015C125.618 25.0799 125.966 24.341 125.966 23.3848C125.966 22.4285 125.618 21.6896 124.966 21.168Z" fill="#276FB7" />

                  <path d="M145.09 17.9951V33.1209H142.656L133.963 22.9066V33.1209H131.051V17.9951H133.485L142.178 28.2094V17.9951H145.09Z" fill="#276FB7" />

                  <path d="M75.7209 2.78121V7.64927H83.4141V10.1702H75.7209V16.2553H72.7218V0.303711H84.3704V2.78121H75.7209Z" fill="#D60000" />

                  <path d="M85.587 14.5607L86.5867 12.2571C87.8472 13.3002 89.8901 13.9957 91.846 13.9957C94.3235 13.9957 95.3667 13.1264 95.3667 11.9094C95.3667 8.47565 85.8913 10.7358 85.8913 4.78113C85.8913 2.21671 87.9341 0 92.3241 0C94.2366 0 96.236 0.478114 97.6268 1.39088L96.7141 3.69451C95.2797 2.86868 93.715 2.43403 92.3241 2.43403C89.8466 2.43403 88.8469 3.39026 88.8469 4.60728C88.8469 7.99753 98.3223 5.78083 98.3223 11.6486C98.3223 14.213 96.236 16.3863 91.8895 16.3863C89.3685 16.4732 86.891 15.7343 85.587 14.5607Z" fill="#D60000" />

                  <path d="M115.752 16.2553L115.708 5.69335L110.492 14.4298H109.145L103.929 5.82375V16.2553H101.104V0.303711H103.538L109.84 10.8657L116.099 0.303711H118.533V16.2553H115.752Z" fill="#D60000" />

                  <path d="M83.675 45.899C83.675 44.3342 82.5015 43.6388 80.3283 43.6388H75.6775V48.1157H80.3283C82.5015 48.1157 83.675 47.4637 83.675 45.899ZM75.6775 37.1191V41.3786H79.7632C81.7626 41.3786 82.8927 40.6832 82.8927 39.2488C82.8927 37.8145 81.7626 37.0756 79.7632 37.0756H75.6775V37.1191ZM86.5872 46.1597C86.5872 48.8111 84.5009 50.3758 80.5021 50.3758H72.7654V34.8589H80.0675C83.8054 34.8589 85.8048 36.4236 85.8048 38.9011C85.8048 40.5093 85.0225 41.6829 83.8054 42.3348C85.4571 42.8564 86.5872 44.1604 86.5872 46.1597Z" fill="#FDBD10" />

                  <path d="M102.843 34.8159V50.3763H100.061V48.377C98.9742 49.8982 97.279 50.5937 95.1493 50.5937C91.4982 50.5937 89.238 48.2466 89.238 43.987V34.8159H92.1067V43.9001C92.1067 46.7253 93.5845 48.0727 95.8012 48.0727C98.1918 48.0727 99.8869 46.6818 99.8869 43.5958V34.8159H102.843Z" fill="#FDBD10" />

                  <path d="M113.361 47.9423C116.838 47.9423 119.055 45.8125 119.055 42.5961C119.055 39.3797 116.795 37.25 113.361 37.25H109.58V47.9423H113.361ZM106.711 34.8159H113.535C118.577 34.8159 122.011 37.9454 122.011 42.5961C122.011 47.2469 118.577 50.3763 113.535 50.3763H106.711V34.8159Z" fill="#FDBD10" />

                  <path d="M131.486 47.9423C134.963 47.9423 137.18 45.8125 137.18 42.5961C137.18 39.3797 134.92 37.25 131.486 37.25H127.704V47.9423H131.486ZM124.792 34.8159H131.573C136.615 34.8159 140.048 37.9454 140.048 42.5961C140.048 47.2469 136.615 50.3763 131.573 50.3763H124.792V34.8159Z" fill="#FDBD10" />

                  <path d="M156 34.8159V45.6387C156 50.2894 153.262 52.7669 148.915 52.7669C146.264 52.7669 144.091 51.9411 142.656 50.5936L143.83 48.3769C145.221 49.6374 146.916 50.2459 148.872 50.2459C151.654 50.2459 153.088 48.8116 153.088 45.7691V44.5955C152.001 45.9864 150.437 46.5949 148.524 46.5949C144.612 46.5949 142.309 44.2913 142.309 40.0752V34.8594H145.177V39.9448C145.177 42.7265 146.612 44.1174 148.915 44.1174C151.349 44.1174 153.044 42.7265 153.044 39.684V34.9028H156V34.8159Z" fill="#FDBD10" />

                </svg><br /><br />
                <h4>Select Your Class</h4>
              </div>
              <form onSubmit={handleSubmit} className='p-2 mainlogindesk'>
                

                {/* <label>Name:</label> */}
                <div className='form-group'>  <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder='Name'
                  required
                /></div>

                {/* <label>Email:</label> */}
                <div className='form-group'>   <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='Email'
                  required
               /></div>

                {/* <label>Roll no:</label> */}
                <div className='form-group'>  <input
                  type="number"
                  name="roll_no"
                  value={formData.roll_no}
                  onChange={handleChange}
                  placeholder='Roll no'
                  required
                /> </div>

                {/* <label>Grade:</label> */}
                <div className='form-group'>   <select name="grade" value={formData.grade} onChange={handleGradeChange} placeholder='Select Grade' required>
                <option value="">Select Grade</option>
                {grades.map((grade) => (
                  <option key={grade.id} value={grade.title}>
                    {grade.title}
                  </option>
                ))}
                </select> </div>

                {/* <label>Section:</label> */}
                <div className='form-group'>  <select name="section" value={formData.section} onChange={handleSectionChange} placeholder='Select Section' required>
                <option value="">Select Section</option>
                {Object.entries(sections).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
                </select> </div>

                {/* <label>Instrument:</label> */}
                <div className='form-group'>   <select name="instrument" value={formData.instrument} onChange={handleInstrumentChange} placeholder='Select Instrument' required>
                <option value="">Select Instruments</option>
                {Object.entries(instruments).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
                </select> </div> 
                <div className="d-flex mt-2" style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '20px'}} >
                  <Button variant="defult" type="submit"  className="btn-lb2 signin-btn"> Register </Button>
                </div>
                <br />
              </form>
              <div className="threedots-css2 w-100">
                <ThreeDots className="gotacenterloader" color="#333" type="ThreeDots" height={20} width={90} visible={loading} />
              </div>  

            </Card>
          </Grid>
        </Grid>
      </div>
    )
}


export default NewUserForm;