const MYSCHOOLS = "MYSCHOOLS";
const MYTODAYSESSION = "MYTODAYSESSION";
const MYCLASSROOMSINSTRUMENTS = "MYCLASSROOMSINSTRUMENTS";
const  SCHEDULELIST="SCHEDULELIST"
const  STANDARDLIST="STANDARDLIST"
const  SECTIONLIST="SECTIONLIST"
const  SESSIONLIST="SESSIONLIST"
const  SESSIONADD ="SESSIONADD"
const MYTODAYSESSIONPROGRESS = "MYTODAYSESSIONPROGRESS";
const SCHOOLEVENTS = "SCHOOLEVENTS";



export { MYSCHOOLS, MYTODAYSESSION, MYCLASSROOMSINSTRUMENTS,SCHEDULELIST,STANDARDLIST, SECTIONLIST,SESSIONLIST, SESSIONADD,MYTODAYSESSIONPROGRESS,SCHOOLEVENTS};
